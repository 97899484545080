import React, {Fragment} from 'react';
import TopNavBar from "../components/TopNavBar";
import AboutContent from "../components/aboutContent";

const AboutPage = () => {
    return (
        <Fragment>
            <TopNavBar/>
            <AboutContent/>
        </Fragment>
    );
};

export default AboutPage;