import React, {Component,Fragment} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./pages/homePage";
import AboutPage from "./pages/aboutPage";
import SchedulePage from "./pages/schedulePage";
class App extends Component {
    render() {
        return (
            <Fragment>
                <audio
                    autoPlay="true"
                    ref={(audio) => (this.audioInput = audio)}
                    src="https://studio.scoutradio.id/stream"
                />
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" render={(props) => <HomePage {...props} key={Date.now()}/>}/>
                        <Route exact path="/About" render={(props) => <AboutPage {...props} key={Date.now()}/>}/>
                        <Route exact path="/Schedule" render={(props) => <SchedulePage {...props} key={Date.now()}/>}/>
                    </Switch>
                </BrowserRouter>
            </Fragment>
        );
    }
}
export default App;
