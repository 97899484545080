import React, {Fragment,Component} from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";

class AboutContent extends Component {

    constructor() {
        super();
        this.state={
            content:""
        }
    }

    componentDidMount() {

        // Get About HTML Content From Data Directory
        axios.get("data/about.txt").then((res)=>{
            this.setState({content:res.data})
        })

    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid my-5">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10 col-sm-10 col-lg-10 col-12">
                            <div className="card animated fadeIn">
                                <div className="card-body">
                                   <div className="animated fadeIn">
                                       { ReactHtmlParser(this.state.content) }
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default AboutContent;