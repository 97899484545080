import React, {Fragment,Component} from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import NavLogo from '../assets/images/nav-logo.png'
import {NavLink} from "react-router-dom";
import {AiOutlineInstagram,AiOutlineTwitter, FiFacebook} from "react-icons/all";
import axios from "axios";
class TopNavBar extends Component {

    constructor() {
        super();
        this.state={
            facebookLink:"",
            twitterLink:"",
            instagramLink:""
        }
    }

    componentDidMount() {

        // Get Facebook Page Link From Data Directory
        axios.get("data/facebook-link.txt").then((res)=>{
            this.setState({facebookLink:res.data})
        })

        // Get Twitter Page Link From Data Directory
        axios.get("data/twitter-link.txt").then((res)=>{
            this.setState({twitterLink:res.data})
        })

        // Get Instagram Page Link From Data Directory
        axios.get("data/instagram-link.txt").then((res)=>{
            this.setState({instagramLink:res.data})
        })
    }


    render() {
        return (
            <Fragment>
                <Navbar variant="dark" expand="lg">
                    <Container>
                        <Navbar.Brand> <NavLink className="nav-link" to="/"> <img alt="nav-logo" className="nav-logo" src={NavLogo}/></NavLink></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavLink exact activeClassName="nav-link-active" className="nav-link" to="/">Home</NavLink>
                                <NavLink exact activeClassName="nav-link-active" className="nav-link" to="/About">About</NavLink>
                                <NavLink  exact activeClassName="nav-link-active" className="nav-link" to="/Schedule">Schedule</NavLink>
                            </Nav>
                            <div className="d-flex">
                                <a target="_blank" className="nav-link-social" href={this.state.facebookLink}><FiFacebook/></a>
                                <a target="_blank" className="nav-link-social" href={this.state.twitterLink}><AiOutlineTwitter/></a>
                                <a target="_blank" className="nav-link-social" href={this.state.instagramLink}><AiOutlineInstagram/></a>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Fragment>
        );
    }
}

export default TopNavBar;