import React, {Fragment} from 'react';
import TopNavBar from "../components/TopNavBar";
import HomeRadio from "../components/homeRadio";
const HomePage = () => {
    return (
        <Fragment>
            <Fragment>
                <TopNavBar/>
                <HomeRadio/>
            </Fragment>
        </Fragment>
    );
};
export default HomePage;