import React, {Fragment} from 'react';
import TopNavBar from "../components/TopNavBar";
import ScheduleContent from "../components/scheduleContent";
const SchedulePage = () => {
    return (
        <Fragment>
            <TopNavBar/>
            <ScheduleContent/>
        </Fragment>
    );
};

export default SchedulePage;