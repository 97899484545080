import React, {Component, Fragment} from 'react';
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

class ScheduleContent extends Component {
    constructor() {
        super();
        this.state={
            content:""
        }
    }
    componentDidMount() {

        // Get Schedule HTML Content From Data Directory
        axios.get("data/schedule.txt").then((res)=>{
            this.setState({content:res.data})
        })

    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid my-5">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10 col-sm-10 col-lg-10 col-12">
                            <div className="card animated fadeIn">
                                <div className="card-body">
                                    <div className="animated fadeIn">
                                        { ReactHtmlParser(this.state.content) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default ScheduleContent;